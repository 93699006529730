<template>
  <base-layout>
    <details-page-title title="Marketplace Management" :details-id="id">
    </details-page-title>

    <details-page-header
      :name="header.brand_name"
      :avatar="header.brand_image"
      :badge="header.cityName"
      :tabs="tabs"
      :marketplaceCardInfo="summary"
      :marketplaceCurrencySymbol="marketplaceCurrencySymbol"
      slotContainerClass="justify-between"
      rightSlotClass="justify-end"
    >
    </details-page-header>

    <div class="min-h-screen">
      <router-view />
    </div>
  </base-layout>
</template>

<script>
import { MarketplaceConfig } from '@/config/MarketplaceConfig'

import BaseLayout from '@/views/shared/BaseLayout'

import DetailsPageTitle from '@/components/ui/DetailsPageTitle.vue'
import DetailsPageHeader from '../../components/ui/DetailsPageHeader.vue'

export default {
  name: 'MarketplaceDetails',
  components: {
    BaseLayout,
    DetailsPageTitle,
    DetailsPageHeader,
  },
  data() {
    return {
      header: {
        brand_name: '',
        brand_image: '',
        countryName: '',
      },
      marketplaceCurrencySymbol: '',
      tabs: [
        { text: 'Profile', href: this.getTabHref(`profile`) },
        { text: 'Vehicles', href: this.getTabHref(`vehicles`) },
        { text: 'Purchase', href: this.getTabHref(`purchase`) },
        { text: 'Revenues', href: this.getTabHref(`revenues`) },
      ],
      summary: {
        number_of_vehicles: 0,
        order_value: 0,
        collected: 0,
      },
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  async created() {
    await this.$http
      .get(MarketplaceConfig.api.details(this.id))
      .then((res) => {
        console.log('marketplace-header', res)

        this.header.brand_name = res.data.data.brand_name
        this.header.brand_image = res.data.data.brand_image
        this.header.cityName = res.data.data.fleet.city.name
        this.summary = res.data.data.summary
        this.marketplaceCurrencySymbol =
          res.data.data.fleet.country.currency_symbol
      })
      .catch((err) => {
        console.log('marketplace-header-err', err)
      })
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    getTabHref(path) {
      return `/marketplaces/${this.$route.params.id}/${path}`
    },
  },
}
</script>

<style lang="scss" scoped></style>
